import React, { Fragment } from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography } from "@material-ui/core"

import Link from "components/link"
import Edges from "components/edges"
import BackgroundImage from "components/backgroundImage"

const MemberSections = (props) => {
  const { sections } = props

  return (
    <Container>
      {sections && (
        <Sections>
          {sections.map(({ membersBackgroundImage, membersHeadline, members }, index) => (
            <Section
              key={index}
              css={
                index === sections.length - 1
                  ? css`
                      padding: 110px 0 230px;
                    `
                  : css`
                      padding: 110px 0 0;
                    `
              }
            >
              <Edges
                size="md"
                css={css`
                  position: relative;
                  z-index: 1;
                `}
              >
                {membersHeadline && <Headline variant="h2">{membersHeadline}</Headline>}

                {members && (
                  <Members>
                    {members.map(({ logo, link }, index) => {
                      return (
                        <Fragment key={index}>
                          {logo?.localFile?.childImageSharp?.gatsbyImageData && (
                            <LogoContainer to={link.url} count={members.length}>
                              <GatsbyImage image={logo.localFile.childImageSharp.gatsbyImageData} alt={link.title} />
                            </LogoContainer>
                          )}
                        </Fragment>
                      );
                    })}
                  </Members>
                )}
              </Edges>

              {membersBackgroundImage && <BackgroundImage image={membersBackgroundImage} />}
            </Section>
          ))}
        </Sections>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  z-index: 5;
  background: #c8dadb;
`
const Sections = styled.div``

const Section = styled.div`
  position: relative;
  text-align: center;
`

const Members = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
  width: 100%;
  height: 180px;
  margin: 0 15px 30px;

  @media (min-width: 550px) {
    width: calc((100% / 2) - 20px);
  }
  @media (min-width: 800px) {
    width: calc((100% / 3) - 35px);
  }

  ${(props) =>
    props.count > 9 &&
    `
    @media (min-width: 1024px) {
      width: calc((100% / 5) - 35px);
      height: 120px;
    }
  `}

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;

    img {
      object-fit: contain !important;
    }
  }
`

const Headline = styled(Typography)`
  margin-bottom: 80px;
`

export default MemberSections

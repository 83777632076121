import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

import BackgroundImage from "components/backgroundImage"
import Textarea from "components/textarea"
import Edges from "components/edges"
import ImageCaption from "./ImageCaption"
import Button from "./Button"
import ExploreIcon from "../../assets/svg/exploreIcon.svg"

const Banners = (props) => {
  const { banners } = props

  return (
    <Container>
      {banners && (
        <div>
          {banners.map(({ alignCard, backgroundImage, imageCaption, headline, text, button }, index) => {
            return (
              <Banner key={index}>
                <StyledEdges size="lg" align={alignCard}>
                  <Content align={alignCard}>
                    {headline && <Typography variant="h2">{headline}</Typography>}
                    {text && <Textarea content={text} />}
                    {button && (
                      <Button to={button.url} aria-label={`${button.title} - ${headline}`}>
                        {button.title}
                        <ExploreIcon />
                      </Button>
                    )}
                  </Content>
                </StyledEdges>

                {imageCaption && <ImageCaption caption={imageCaption} />}

                {backgroundImage && <BackgroundImage className="banner-bg-img" image={backgroundImage} />}
              </Banner>
            )
          })}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Banner = styled.div`
  position: relative;
  padding: 80px 0;
  max-width: 100vw;
  overflow: hidden;

  @media (min-width: 800px) {
    padding: 168px 0;
  }

  .banner-bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
  }
`

const StyledEdges = styled(Edges)`
  display: flex;
  justify-content: ${(props) => (props.align === "left" ? "flex-start" : "flex-end")};
`

const Content = styled.div`
  background: white;
  z-index: 3;
  position: relative;
  padding: 50px 20px;

  @media (min-width: 800px) {
    max-width: 600px;
    ${(props) => (props.align === "left" ? `padding-right: 84px;` : `padding-left: 84px;`)}
  }

  &:after {
    content: "";
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    top: 0;
    z-index: -1;

    @media (min-width: 800px) {
      ${(props) => (props.align === "left" ? `left: -30vw;` : `right: -30vw;`)}
    }
  }

  img {
    box-shadow: none !important;
  }
`

export default Banners

import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

import Edges from "components/edges"
import HouseIcon from "../../../static/images/icons/house.svg"

const ImageCaption = (props) => {
  const { caption } = props

  return (
    <Container>
      <Edges size="lg">
        <Caption>
          <Grid container alignItems="center">
            <HouseIcon />
            <StyledTypography variant="caption" children={caption} color="inherit" />
          </Grid>
        </Caption>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  z-index: 2;
`

const Caption = styled.div`
  display: inline-block;
  padding: 6px 8px;
  background: #000;
  color: #fff;

  svg {
    @media (max-width: 640px) {
      display: none;
    }
  }
`

const StyledTypography = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f5f5f5;

  @media (min-width: 640px) {
    margin-left: 10px;
  }
`

export default ImageCaption

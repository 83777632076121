import React from "react"
import styled from "styled-components"
import { Button as MuiButton } from "@material-ui/core"

import Link from "components/link"

const HomeButton = (props) => {
  const { to, ...rest } = props
  return (
    <Link to={to}>
      <Button {...rest} />
    </Link>
  )
}

const Button = styled(MuiButton)`
  padding: 6px 24px;
  height: 50px;
  border-radius: 40px;
  border: 1px solid #dedede;
  text-transform: unset;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  svg {
    width: 36px;
    height: 36px;
    margin-left: 14px;
  }
`

export default HomeButton

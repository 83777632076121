import React from "react"
import styled, { css } from "styled-components"
import Parser from "html-react-parser"
import { Typography } from "@material-ui/core"

import BackgroundImage from "components/backgroundImage"
import Edges from "components/edges"
import Button from "./Button"
import ImageCaption from "./ImageCaption"
import ArrowIcon from "../../assets/svg/arrowIcon.svg"

const HomeHero = (props) => {
  const { heroHeadline, heroTagline, heroImage, heroButton, heroImageCaption } = props

  return (
    <Container>
      <HeroHeader>
        <Edges size="lg" css={css``}>
          {heroTagline && (
            <Typography variant="h4" gutterBottom>
              {Parser(heroTagline)}
            </Typography>
          )}
          <div
            css={css`
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              flex-wrap: wrap;
            `}
          >
            {heroHeadline && <Headline variant="h1">{Parser(heroHeadline)}</Headline>}
            {heroButton && (
              <StyledButton to={heroButton.url}>
                {heroButton.title}
                <ArrowIcon />
              </StyledButton>
            )}
          </div>
        </Edges>
      </HeroHeader>

      {heroImage && (
        <ImageContainer>
          <BackgroundImage image={heroImage} />
          {heroImageCaption && <ImageCaption caption={heroImageCaption} />}
        </ImageContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 95vh;
  height: calc(100vh - 102px);
`

const HeroHeader = styled.div`
  position: relative;
  padding: 30px 0;
  z-index: 1;
  background: white;
  transition: margin ease 0.2s;
`

const ImageContainer = styled.div`
  background: grey;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`

const Headline = styled(Typography)`
  margin-right: 20px;
  width: 100%;

  @media (min-width: 960px) {
    width: auto;
  }
`

const StyledButton = styled(Button)`
  && {
    margin-top: 20px;

    @media (min-width: 960px) {
      margin-bottom: 10px;
    }

    @media (min-width: 1280px) {
      margin-bottom: 14px;
    }
  }
`

export default HomeHero
